import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';

const authStore = createStore((set) => ({
  accessToken: null,
  refreshToken: null,
  authSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  setAuthToken: (accessToken) => set({ accessToken }),
  setRefreshToken: (refreshToken) => set({ refreshToken }),
  setTokens: (accessToken, refreshToken) => set({ accessToken, refreshToken }),
  logout: () => set({ accessToken: null, refreshToken: null }),
}));

const useAuthStore = (selector) => useStore(authStore, selector);

export {
  authStore, useAuthStore,
};
