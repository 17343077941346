/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from '@emotion/styled/macro';
import { Chip } from '@mui/material';

import { useAuthStore } from 'login/hooks/useAuthStore';
import { useSignIn } from 'login/hooks/useSignIn';

const styles = {
  center: css({
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    display: 'flex',
    '& .MuiFormControl-root': {
      width: '450px',
      margin: '0.5rem',
    }
  }),
  container: css({
    textAlign: 'center',
  }),
};

const StyledClipboardDiv = styled(Chip)`
  background-color: #1e96f0;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const HelperText = styled.div`
  margin-top: 1rem;
  font-size: 12px;
  color: gray;
`;

function View({ refreshToken, onCopy }) {
  return (
    <div css={styles.center}>
      <div css={styles.container}>
        {refreshToken && (
          <div>
            <CopyToClipboard
              text={refreshToken}
              onCopy={onCopy}
            >
              <StyledClipboardDiv label={`Token: ${refreshToken}`} />
            </CopyToClipboard>
            <HelperText>
              What next? Click the token to copy it to your clipboard. Then paste it (Cmd + V) into Stratolaunch!
            </HelperText>
          </div>
        )}
        {!refreshToken && (
          <div>
            Logging In
          </div>
        )}
      </div>
    </div>
  );
}

function Model() {
  const {
    authSecret,
    logout,
    accessToken,
    refreshToken
  } = useAuthStore();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [searchParams] = useSearchParams();

  const authCode = searchParams.get('code');
  const isDenied = searchParams.get('outcome') === 'denied';

  useEffect(() => {
    if (isDenied) {
      toast.error('Access denied due to oauth denial.');
      logout();
      navigate('/');
    }
  }, [isDenied, logout, navigate]);

  useEffect(() => {
    if (!accessToken && authSecret && authCode) {
      signIn({ secret: authSecret, authCode });
    }
  }, [accessToken, authCode, authSecret, signIn]);

  const onCopy = () => {
    toast.success('Copied to clipboard!');
  };

  const hookProps = {
    secret: authSecret,
    refreshToken,
    onCopy,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

export default Model;
export { View };
